import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//guard router
function guardMyRoute(to, from, next)
{
  const publicPages = ['/auth', '/authregister', '/home',''];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  
  //jika membuka halaman yang dilindungi sedangkan blm login maka akan dibawa ke halaman LOGIN
  if(authRequired && !loggedIn)  {
      next('/auth');
  } else {
    next();
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter : guardMyRoute,
      children: [
        {
          name: 'Home',
          beforeEnter : guardMyRoute,
          path: '/home',
          component: () => import('@/views/dashboard/Home'),
        },
        {
          name: 'AuthUser',
          path: '/auth',
          component: () => import('@/views/dashboard/Login'),
        },
        {
          name: 'Dashboard',
          beforeEnter : guardMyRoute,
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Data Induk
        {
          name: 'Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-customer',
          component: () => import('@/views/dashboard/pages/datainduk-customer'),
        },  
        {
          name: 'Grup Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-grupbarang',
          component: () => import('@/views/dashboard/pages/datainduk-grupbarang'),
        },
        {
          name: 'Subgrup Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-subgrupbarang',
          component: () => import('@/views/dashboard/pages/datainduk-subgrupbarang'),
        }, 
        {
          name: 'Merk Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-merk',
          component: () => import('@/views/dashboard/pages/datainduk-merk'),
        },
        {
          name: 'Satuan',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-satuan',
          component: () => import('@/views/dashboard/pages/datainduk-satuan'),
        },
        {
          name: 'Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-barang',
          component: () => import('@/views/dashboard/pages/datainduk-barang'),
        },
        {
          name: 'Price List',
          beforeEnter : guardMyRoute,
          path: 'pages/pricelist',
          component: () => import('@/views/dashboard/pages/pricelist'),
        },
        {
          name: 'Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-operator',
          component: () => import('@/views/dashboard/pages/datainduk-operator'),
        }, 
        {
          name: 'Termin',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-termin',
          component: () => import('@/views/dashboard/pages/datainduk-termin'),
        },
        {
          name: 'Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-jenistindakan',
          component: () => import('@/views/dashboard/pages/datainduk-jenistindakan'),
        },
        {
          name: 'PPN',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-ppn',
          component: () => import('@/views/dashboard/pages/datainduk-ppn'),
        },
        {
          name: 'Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-areapenjualan',
          component: () => import('@/views/dashboard/pages/datainduk-areapenjualan'),
        },
        {
          name: 'Lot Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-lotbarang',
          component: () => import('@/views/dashboard/pages/datainduk-lotbarang'),
        }, 
        {
          name: 'User',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-user',
          component: () => import('@/views/dashboard/pages/datainduk-user'),
        },
        //Invoice
        {
          name: 'Data Invoice',
          beforeEnter : guardMyRoute,
          path: 'pages/invoice-data',
          component: () => import('@/views/dashboard/pages/invoice-data'),
        },
        {
          name: 'Invoice Update',
          beforeEnter : guardMyRoute,
          path: 'pages/invoice-update',
          component: () => import('@/views/dashboard/pages/invoice-update'),
        },
        {
          name: 'Invoice Baru',
          beforeEnter : guardMyRoute,
          path: 'pages/invoice-add',
          component: () => import('@/views/dashboard/pages/invoice-add'),
        },
        // {
        //   name: 'Invoice Layout',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/invoice-add-layout',
        //   component: () => import('@/views/dashboard/pages/invoice-add-layout'),
        // },
        // PAJAK
        {
          name: 'Pajak',
          beforeEnter : guardMyRoute,
          path: 'pages/pajak',
          component: () => import('@/views/dashboard/pages/pajak'),
        },
        // LAPORAN
        // {
        //   name: 'Laporan Per Customer',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoicepercustomer',
        //   component: () => import('@/views/dashboard/pages/laporan-invoicepercustomer'),
        // },
        {
          name: 'Daftar Penjualan Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoicepercustomer',
          component: () => import('@/views/dashboard/pages/laporan-invoicepercustomer'),
        },
        {
          name: 'Daftar Penjualan Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoiceperoperator',
          component: () => import('@/views/dashboard/pages/laporan-invoiceperoperator'),
        },
        {
          name: 'Daftar Penjualan Per Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoiceperareajual',
          component: () => import('@/views/dashboard/pages/laporan-invoiceperareajual'),
        },
        {
          name: 'Daftar Penjualan Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoiceperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-invoiceperjenistindakan'),
        },
        {
          name: 'Daftar Kwitansi',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-kwitansi',
          component: () => import('@/views/dashboard/pages/laporan-kwitansi'),
        },
        {
          name: 'Daftar Surat Jalan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-suratjalan',
          component: () => import('@/views/dashboard/pages/laporan-suratjalan'),
        },
        {
          name: 'Laporan Resum Penjualan Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumpenjualanpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-resumpenjualanpercustomer'),
        },
        {
          name: 'Laporan Resum Penjualan Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumpenjualanperoperator',
          component: () => import('@/views/dashboard/pages/laporan-resumpenjualanperoperator'),
        },
        {
          name: 'Laporan Resum Penjualan Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumpenjualanperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-resumpenjualanperjenistindakan'),
        },
        // ------------------HPP -----------------
        {
          name: 'Daftar HPP Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-hpppercustomer',
          component: () => import('@/views/dashboard/pages/laporan-hpppercustomer'),
        },
        {
          name: 'Daftar HPP Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-hppperoperator',
          component: () => import('@/views/dashboard/pages/laporan-hppperoperator'),
        },
        {
          name: 'Daftar HPP Per Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-hppperareajual',
          component: () => import('@/views/dashboard/pages/laporan-hppperareajual'),
        },
        {
          name: 'Daftar HPP Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-hppperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-hppperjenistindakan'),
        },
        {
          name: 'Laporan Resum HPP Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumhpppercustomer',
          component: () => import('@/views/dashboard/pages/laporan-resumhpppercustomer'),
        },
        {
          name: 'Laporan Resum HPP Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumhppperoperator',
          component: () => import('@/views/dashboard/pages/laporan-resumhppperoperator'),
        },
        {
          name: 'Laporan Resum HPP Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumhppperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-resumhppperjenistindakan'),
        },
        // ------------------ SHARING PROFIT -----------------
        {
          name: 'Daftar Sharing Profit Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-sharingprofitpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-sharingprofitpercustomer'),
        },
        {
          name: 'Daftar Sharing Profit Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-sharingprofitperoperator',
          component: () => import('@/views/dashboard/pages/laporan-sharingprofitperoperator'),
        },
        {
          name: 'Daftar Sharing Profit Per Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-sharingprofitperareajual',
          component: () => import('@/views/dashboard/pages/laporan-sharingprofitperareajual'),
        },
        {
          name: 'Daftar Sharing Profit Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-sharingprofitperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-sharingprofitperjenistindakan'),
        },
        {
          name: 'Laporan Resum Sharing Profit Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumsharingprofitpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-resumsharingprofitpercustomer'),
        },
        {
          name: 'Laporan Resum Sharing Profit Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumsharingprofitperoperator',
          component: () => import('@/views/dashboard/pages/laporan-resumsharingprofitperoperator'),
        },
        {
          name: 'Laporan Resum Sharing Profit Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumsharingprofitperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-resumsharingprofitperjenistindakan'),
        },
        // ----------------- ARUS BARANG KELUAR -------------------------
        
        {
          name: 'Laporan Arus Barang Keluar Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-abkpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-abkpercustomer'),
        },
        {
          name: 'Laporan Arus Barang Keluar Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-abkperoperator',
          component: () => import('@/views/dashboard/pages/laporan-abkperoperator'),
        },
        {
          name: 'Laporan Arus Barang Keluar Per Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-abkperareajual',
          component: () => import('@/views/dashboard/pages/laporan-abkperareajual'),
        },
        {
          name: 'Laporan Arus Barang Keluar Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-abkperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-abkperjenistindakan'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar  Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-resumabkpercustomer'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar  Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkperoperator',
          component: () => import('@/views/dashboard/pages/laporan-resumabkperoperator'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar  Per Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkperareapenjualan',
          component: () => import('@/views/dashboard/pages/laporan-resumabkperareapenjualan'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar  Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-resumabkperjenistindakan'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar Per Merek',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkpermerek',
          component: () => import('@/views/dashboard/pages/laporan-resumabkpermerek'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar Per Nama Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkpernamabarang',
          component: () => import('@/views/dashboard/pages/laporan-resumabkpernamabarang'),
        },
        {
          name: 'Laporan Resum Arus Barang Keluar Per Merek Detail',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resumabkpermerekdetail',
          component: () => import('@/views/dashboard/pages/laporan-resumabkpermerekdetail'),
        },
        
        // ----------------- MARGIN PENJUALAN -------------------------
        {
          name: 'Daftar Margin Penjualan Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-marginpenjualanpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-marginpenjualanpercustomer'),
        },
        {
          name: 'Daftar Margin Penjualan Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-marginpenjualanperoperator',
          component: () => import('@/views/dashboard/pages/laporan-marginpenjualanperoperator'),
        },
        {
          name: 'Daftar Margin Penjualan Per Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-marginpenjualanperareapenjualan',
          component: () => import('@/views/dashboard/pages/laporan-marginpenjualanperareapenjualan'),
        },
        {
          name: 'Daftar Margin Penjualan Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-marginpenjualanperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-marginpenjualanperjenistindakan'),
        },
        {
          name: 'Laporan Resum Margin Penjualan Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resummarginpenjualanpercustomer',
          component: () => import('@/views/dashboard/pages/laporan-resummarginpenjualanpercustomer'),
        },
        {
          name: 'Laporan Resum Margin Penjualan Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resummarginpenjualanperoperator',
          component: () => import('@/views/dashboard/pages/laporan-resummarginpenjualanperoperator'),
        },
        {
          name: 'Laporan Resum Margin Penjualan Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-resummarginpenjualanperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-resummarginpenjualanperjenistindakan'),
        },
        
      ],
    },
  ],
})
